.features {
    padding-top: 100px;
  padding-bottom: 100px;
background: rgba(255, 255, 255, 0.05);
@include media("<=810px") {
    padding-bottom: 80px;
}

}

.features__inner {
    padding: 40px 0;
    display: flex;

    gap: 20px;
    justify-content: space-between;
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
        @include media("<=550px") {
            flex-direction: column;
            align-items: center;
        }
}

.features__subtitle {
    padding-top: 20px;
    font-size: 15px;
}

.features__item {


    display: flex;
    flex-direction: column;
    gap: 15px;
    line-height: 1.2;
    justify-content: flex-start;
    flex: 0 1 48%;
    width: 100%;
    font-size: 14px;

    
    @include media("<=1080px") {
      
    }
    
        @include media("<=810px") {
        flex: 0 1 100%;
            
        }
        @include media("<=550px") {
            font-size: 16px;
            width: 100%;
            flex: 0 1 100%;
        }
        
}

.features__title{
    font-size: 28px;
    font-weight: 500;
    text-transform: uppercase;
    @include media("<=600px") {
        font-size: 24px;
    }
}
body {
  margin: 0;
font-family: "Montserrat", serif;
  color: #fcfcfc;
  background-color: #000;
  position: relative;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
}

.section__title {

  font-size: 64px;
  font-weight:700;
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  gap: 20px;
  @include media("<=1080px") {
    font-size: 40px;
  }
  @include media("<=810px") {
    flex-direction: column;
    align-items: flex-start;
  }
  @include media("<=430px") {
    font-size: 28px;
  } 
  @include media("<=350px") {
   
  }
  span {
    font-size: 14px;
    max-width: 357px;
    line-height: 1.2;
    text-transform: none;
    @include media("<=430px") {
      font-size: 12px;
    }
  }
}

.section__subtitle {
  padding-top: 30px;
  font-weight: 300;
  max-width: 772px;
}

.consent {
border-radius: 30px;
  background: #F4F4F4;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    max-width: 630px;
    width: 95%;
    position: fixed;
    left: 10%;
    bottom: 50px;
    transform: translateX(-10%);
    z-index: 21;
    @include media("<=810px") {
      left: 50%;
      transform: translateX(-50%);
    }
    img {
      max-width: 40px;
    }
}

.consent__text {
  font-size: 14px;
  color: #131313;
  span {
    display: block;
    font-size: 30px;
    text-transform: uppercase;
  }
}

.consent__buttons {
  display: flex;
 
  gap: 30px;
  align-items: center;
  width: 100%;
  @include media("<=810px") {
    justify-content: center;
  }
    @include media("<=600px") {
       flex-direction: column;
    }
}

.close {
  color: #0D83F0;
  font-size: 14px;
  font-weight: 600;
  width: fit-content;
  text-decoration: none;
  padding: 20px;
  text-align: center;
  border-radius: 60px;
    border: 1px solid #0D83F0;
}

.hide {
  display: none;
}

.page__title {
  font-size: 64px;
  font-weight: 800;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.page__content {
  padding: 140px 0 100px;
}

.page__inner {
  padding-top: 40px;
  width: 100%;
  margin: 0 auto;
  display: flex;
    flex-direction: column;
    row-gap: 18px;
    p {
      padding-bottom: 10px;
    }
    strong {
      padding: 8px 0 10px;
      display: block;
      font-weight: 600;
    }
    ul {
      padding-left: 20px;
      list-style-type: disc;
    }
}

.page__text {
  line-height: 1.2;
  font-size: 15px;
  font-weight: 400;
  p {
    padding-top: 18px;
  }
}

.page__section {
  @include media("<=550px") {
      padding-top: 230px !important;
      
    }
}



.about {
  overflow-x: clip;
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  background: rgba(255, 255, 255, 0.05);
  @include media("<=1024px") {
  }
  @include media("<=810px") {
  
  }
  @include media("<=430px") {
    
  }
  
}

.about__inner {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 2;
  gap: 26px;

  @include media("<=810px") {
     flex-direction: column;
     align-items: center;
     row-gap: 30px;
  }
  @include media("<=500px") {
   
  }
}

.about__content {
  max-width: 758px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about__text {
  padding: 20px 0;
  font-size: 14px;
    font-weight: 400;
    line-height: 1.1;
    display: flex;
      flex-direction: column;
      row-gap: 15px;
    @include media("<=430px") {
      font-size: 14px;
    }
}

.about__title {
  font-size: 26px;
  font-weight: 500;
  text-transform: uppercase;
  p {
    font-size: 14px;
    text-transform: none;
    padding-top: 12px;
  }
}


.about__image {
  max-width: 388px;
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
}

.about__items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-bottom: 30px;
  @include media("<=650px") {
    flex-direction: column;
  }
}

.about__item {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 0 1 48%;
 
    @include media("<=650px") {
           flex: 0 1 100%;
           width: 100%;
     }
}










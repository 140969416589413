.footer {
  padding: 38px 0;
  position: relative;
  @include media("<=810px") {
    overflow: visible;
    padding: 30px 0;
  }
  @include media("<=430px") {
    
  }
  
}

.footer__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 22px;
  @include media("<=650px") {
    flex-wrap: wrap;
    gap: 30px;
  }
}

.footer__nav {
  column-gap: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @include media("<=810px") {
    justify-content: flex-start;
  }
}

.copyright, .footer__text {
  padding-top: 20px;
  color: rgba(255, 255, 255, 0.50);
  font-size: 14px;
  font-weight: 400;

  @include media("<=810px") {
    font-size: 12px;
  }
}

.footer__row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  @include media("<=810px") {
    justify-content: flex-start;
  }
    @include media("<=650px") {
      flex-direction: column;
      align-items: flex-start;
    }
}
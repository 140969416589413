.hero {
  position: relative;
  padding: 107px 0 ;
  background-image: url(../images/hero-bcg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include media("<=1080px") {

  }

  @include media("<=810px") {
   padding: 160px 0 80px;
   background-position: right center;
  }

  @include media("<=550px") {
    padding-top: 150px;
    
  }
}

.hero__inner {

  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  gap: 26px;
  padding-bottom: 30px;
  @include media("<=1280px") {

    margin: 0 auto;
  }

  @include media("<=1080px") {}

  @include media("<=810px") {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
   row-gap: 30px;
  }

  @include media("<=430px") {}
}

.hero__content {
  max-width: 895px;
  width: 100%;
  line-height: 1.2;
  @include media("<=810px") {
   
  }
}

.hero__title {
  font-size: 70px;
  font-weight: 700;

  position: relative;
  z-index: 3;
  text-transform: uppercase;
  margin-bottom: 30px;
  line-height: 1.1;
  @include media("<=1280px") {
   
    
  }

  @include media("<=1080px") {
    

  }

  @include media("<=810px") {
   font-size: 54px;
    
  }
    @include media("<=550px") {
      font-size: 40px;
  
    }

  @include media("<=430px") {
    font-size: 32px;

  }


  @include media("<=350px") {
    
  }
}

.hero__subtitle {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.hero__text {
  max-width: 387px;
  font-size: 16px;

  @include media("<=1280px") {
   
  }
  @include media("<=430px") {
    
  }
}

.button {
  font-family: "Montserrat", serif;
    border-radius: 50px;
      background: #5817C0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    padding: 24px;
    width: 100%;
    max-width: 320px;
    border: none;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s linear;
  
    

  @include media("<=810px") {
   
  }
 
  &:hover {
    background: #2748ec;
  }
  &-consent {
    max-width: 198px;
  }
}



.hero__image {
  flex: 0 1 100%;
  width: 100%;
  position: relative;
  max-width: 183px;
    overflow: hidden;
  @include media("<=810px") {
   max-width: 550px;
  }

  @include media("<=430px") {
    
  }

  img {
    width: 100%;
    display: block;
    @include media("<=1280px") {
      
    }
  }
}


.header {
  width: 100%;
  padding: 20px 0;
  position: absolute;
  left: 0;
  top: 0;
  @include media("<=810px") {

  }
  @include media("<=430px") {
  
  }
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
  position: relative;
  gap: 20px;
  @include media("<=810px") {
    flex-wrap: wrap;
    gap: 20px;
    
  }
    
}

.header__nav {
  gap: 30px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include media("<=810px") {
    justify-content: flex-start;
    column-gap: 40px;
    order: 3;
  }
    @include media("<=550px") {
      justify-content: space-between;
      gap: 20px;
      flex-direction: column;
      align-items: self-start;
    }
}

.header__link {
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  transition: all 0.3s linear;
  @include media("<=550px") {
    order: 2;
  }
  &:hover {
    

  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.08em;
  background: #fdfdfd;
  bottom: -6px;
  left: 0;
  border-radius: 3px;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}

.header__logo {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 24px;
  max-width: 306px;
  font-weight: 700;
  @include media("<=810px") {
  
    width: fit-content;
  }
    @include media("<=550px") {
      order: 1;
    }
    img {
      width: 100%;
    }
}

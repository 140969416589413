.reviews {
    padding-bottom: 80px;
}

.reviews__inner {
    display: flex;
    justify-content: flex-start;
    gap: 26px;
    align-items: center;
    @include media("<=720px") {
        flex-direction: column;
        align-items: center;
    }
}

.reviews__image {
  max-width: 440px;
  width: 100%;
  img {
    width: 100%;
  }
}

.reviews__content {
    max-width: 590px;
    width: 100%;
}

.reviews__item {
    margin-top: 20px;
    span {
        font-size: 28px;
        font-weight: 500;
        display: block;
        text-transform: uppercase;
        margin-bottom: 8px;
    }
}
.info {
    padding: 100px 0;
    @include media("<=810px") {
  
    }
    
}

.info__inner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 20px;
    
    @include media("<=810px") {
       flex-direction: column-reverse;
       justify-content: center;
       align-items: center;
       gap: 20px;
    }
}

.info__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    max-width: 793px;
    width: 100%;
    @include media("<=650px") {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }
}

.info__items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
}

.info__item {
border-radius: 30px;
    border: 1px solid rgba(252, 252, 252, 0.40);
        flex: 0 1 48%;
      padding: 20px;
        line-height: 1.2;
        @include media("<=810px") {
            flex: 0 1 100%;
            width: 100%;
        }
}

.info__title {
    font-size: 20px;
    font-weight: 500;
    padding: 15px 0;
    text-transform: uppercase;
}


.info__text {
    
        font-size: 16px;
        font-weight: 400;
  
            @include media("<=1080px") {
                row-gap: 14px;
                
            }
        @include media("<=810px") {
          
        }

}

.info__image {
    max-width: 377px;
    img {
        width: 100%;
        display: block;
    }
}
